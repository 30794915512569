.product-card {
    display: flex;
    flex-direction: row;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    max-width: 800px;
    margin: 10% auto;
  }
  
  .product-image {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    border-radius: 8px;
    flex-basis: 100%;
  }
  
  .product-info {
    padding: 30px;
    font-family: sans-serif;
    flex-basis: 100%;
  }
  
  .product-name {
    margin: 0;
    font-size: 26px;
    font-weight: bold;
    color: #212121;
    line-height: 34px;
  }
  
  .product-price {
    margin: 15px 0px;
    font-size: 30px;
    color: #f85606;
  }
  
  .add-to-cart,
  .out-of-stock {
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .add-to-cart {
    background-color: #f57224;
    color: white;
    width: 100%;
    height: 56px;
    font-size: 22px;
  }
  
  .add-to-cart.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .out-of-stock {
    background-color: #f44336;
    color: white;
  }

  .quantity-controls {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }
  
  .quantity-button {
    background-color: #dcdcdc;
    color: #000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 24px;
    width: 50px;
    height: 50px;
}
  
.quantity {
  margin: 0 24px;
  font-size: 22px;
}
  
  .quantity-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  